import React, { useRef, useEffect,useContext ,useState} from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./Main_forth.scss";
import { PopupContext } from "../Popup/Popupcontext";



export default function Scrollering() {
  const { currentLanguage } = useContext(PopupContext);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "end start"],
  });
  const [cachedSlider2, setCachedSlider2] = useState(null);
  useEffect(() => {
    if (!cachedSlider2 && currentLanguage.slider2) {
      setCachedSlider2(currentLanguage.slider2);
    }
  }, [currentLanguage, cachedSlider2]);

  const x1 = useTransform(scrollYProgress, [0, 1], [0, 150]);
  const x2 = useTransform(scrollYProgress, [0, 1], [0, -150]);
  const height = useTransform(scrollYProgress, [0, 0.9], [50, 0]);

  const handleClick = (index, sliderNumber) => {
    navigate(`/images_scrool/detail/${sliderNumber}/${index}`);
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleCanPlay = () => {
        video.play();
        video.removeEventListener("canplay", handleCanPlay);
      };
      video.addEventListener("canplay", handleCanPlay);
      return () => {
        if (video) {
          video.removeEventListener("canplay", handleCanPlay);
        }
      };
    }
  }, []);

  return (
    <div
      ref={container}
      className="slidingImages sm:w-[30%] sm:h-[30%] vsm:w-[100%] vsm:h-[50%] md:w-[100%] overflow-hidden sm:flex md:flex justify-center"
    >
      {/* Slider 1 */}
      <motion.div style={{ x: x1 }} className="slider overflow-hidden cursor-pointer">
        {currentLanguage.slider1.map((project, index) => (
          <div
            key={index}
            className="project"
            style={{ backgroundColor: project.color }}
            onClick={() => handleClick(index, 1)} // Handle click for slider1
          >
            <div className="imageContainer">
              <video ref={videoRef} type="video/mp4" autoPlay loop muted playsInline>
                <source src={project.mainsource} />
              </video>
            </div>
          </div>
        ))}
      </motion.div>

      {/* Slider 2 */}
      <motion.div style={{ x: x2 }} className="slider overflow-hidden , cursor-pointer">
        {cachedSlider2?.map((project, index) => (
          <div
            key={index}
            className="project"
            style={{
              backgroundColor: project.color,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => handleClick(index, 2)} // Handle click for slider2
          >
            <div
              className="videoContainer"
              style={{ width: "90%", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{ width: "100%", height: "120%", objectFit: "cover" }}
              >
                <source src={project.mainsource} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        ))}
      </motion.div>

      <motion.div style={{ height }} className="circleContainer">
        <div className="circle"></div>
      </motion.div>
    </div>
  );
}