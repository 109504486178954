import React from 'react';
import './iphone.css'; // import your custom styles for positioning


const IphoneSVG = ({value}) => {
  return (
    <div className="iphone-container mb-10 mt-10 ">
<svg xmlns="http://www.w3.org/2000/svg"  width="900" height="1200" viewBox="0 0 1637 3158">
<path fill-rule="nonzero" fill="rgb(0%, 0%, 0%)" fill-opacity="1" d="M 248.722656 7.230469 L 1388.859375 7.230469 C 1513.160156 7.230469 1613.230469 107.300781 1613.230469 231.589844 L 1613.230469 2913.765625 C 1613.230469 3038.0625 1513.160156 3138.128906 1388.859375 3138.128906 L 248.722656 3138.128906 C 124.425781 3138.128906 24.359375 3038.0625 24.359375 2913.765625 L 24.359375 231.589844 C 24.359375 107.300781 124.425781 7.230469 248.722656 7.230469 Z M 248.722656 7.230469 "/>
<path fill-rule="nonzero" fill="rgb(30.200195%, 30.200195%, 30.200195%)" fill-opacity="1" d="M 576.324219 111.488281 L 940.710938 111.488281 C 955.128906 111.488281 966.738281 124.558594 966.738281 140.78125 C 966.738281 157.011719 955.128906 170.078125 940.710938 170.078125 L 576.324219 170.078125 C 561.902344 170.078125 550.292969 157.011719 550.292969 140.78125 C 550.292969 124.558594 561.902344 111.488281 576.324219 111.488281 Z M 576.324219 111.488281 "/>
<path fill-rule="nonzero" fill="rgb(30.200195%, 30.200195%, 30.200195%)" fill-opacity="1" d="M 1087.289062 140.78125 C 1087.289062 157.820312 1074.710938 171.621094 1059.210938 171.621094 C 1043.691406 171.621094 1031.128906 157.820312 1031.128906 140.78125 C 1031.128906 123.75 1043.691406 109.949219 1059.210938 109.949219 C 1074.710938 109.949219 1087.289062 123.75 1087.289062 140.78125 Z M 1087.289062 140.78125 "/>
<path fill-rule="nonzero" fill="rgb(100%, 100%, 100%)" fill-opacity="1" d="M 270.585938 98.691406 C 168.84375 98.691406 86.9375 192.910156 86.9375 309.949219 L 86.9375 2835.414062 C 86.9375 2952.449219 168.84375 3046.667969 270.585938 3046.667969 L 1367 3046.667969 C 1468.738281 3046.667969 1550.648438 2952.449219 1550.648438 2835.414062 L 1550.648438 309.949219 C 1550.648438 192.910156 1468.738281 98.691406 1367 98.691406 L 1201.429688 98.691406 C 1202.53125 105.960938 1203.210938 113.390625 1203.210938 121.03125 C 1203.210938 192.789062 1152.988281 250.558594 1090.601562 250.558594 L 546.980469 250.558594 C 484.597656 250.558594 434.375 192.789062 434.375 121.03125 C 434.375 113.390625 435.054688 105.960938 436.148438 98.691406 Z M 270.585938 98.691406 "/>
<path fill-rule="nonzero" fill="rgb(0%, 0%, 0%)" fill-opacity="1" d="M 1623.390625 747.210938 C 1630.679688 747.210938 1636.550781 755.441406 1636.550781 765.671875 L 1636.550781 1200.070312 C 1636.550781 1210.289062 1630.679688 1218.519531 1623.390625 1218.519531 C 1616.109375 1218.519531 1610.238281 1210.289062 1610.238281 1200.070312 L 1610.238281 765.671875 C 1610.238281 755.441406 1616.109375 747.210938 1623.390625 747.210938 Z M 1623.390625 747.210938 "/>
<path fill-rule="nonzero" fill="rgb(0%, 0%, 0%)" fill-opacity="1" d="M 7.9375 758.410156 L 19.269531 758.410156 C 23.421875 758.410156 26.761719 762.171875 26.761719 766.839844 L 26.761719 965.269531 C 26.761719 969.941406 23.421875 973.699219 19.269531 973.699219 L 7.9375 973.699219 C 3.789062 973.699219 0.449219 969.941406 0.449219 965.269531 L 0.449219 766.839844 C 0.449219 762.171875 3.789062 758.410156 7.9375 758.410156 Z M 7.9375 758.410156 "/>
<path fill-rule="nonzero" fill="rgb(0%, 0%, 0%)" fill-opacity="1" d="M 7.9375 992.039062 L 19.269531 992.039062 C 23.421875 992.039062 26.761719 995.800781 26.761719 1000.46875 L 26.761719 1198.898438 C 26.761719 1203.570312 23.421875 1207.328125 19.269531 1207.328125 L 7.9375 1207.328125 C 3.789062 1207.328125 0.449219 1203.570312 0.449219 1198.898438 L 0.449219 1000.46875 C 0.449219 995.800781 3.789062 992.039062 7.9375 992.039062 Z M 7.9375 992.039062 "/>
<path fill-rule="nonzero" fill="rgb(0%, 0%, 0%)" fill-opacity="1" d="M 16.316406 463.109375 C 24.21875 463.109375 30.578125 471.519531 30.578125 481.980469 L 30.578125 604.96875 C 30.578125 615.429688 24.21875 623.839844 16.316406 623.839844 C 8.414062 623.839844 2.054688 615.429688 2.054688 604.96875 L 2.054688 481.980469 C 2.054688 471.519531 8.414062 463.109375 16.316406 463.109375 Z M 16.316406 463.109375 "/>
<path fill-rule="nonzero" fill="rgb(64.646912%, 64.646912%, 64.646912%)" fill-opacity="1" d="M 222.964844 0.5 C 112.523438 0.5 23.613281 101.410156 23.613281 226.738281 L 23.613281 2931.261719 C 23.613281 3056.59375 112.523438 3157.496094 222.964844 3157.496094 L 1413.128906 3157.496094 C 1523.570312 3157.496094 1612.480469 3056.59375 1612.480469 2931.261719 L 1612.480469 226.738281 C 1612.480469 101.410156 1523.570312 0.5 1413.128906 0.5 Z M 233.113281 17.929688 L 1402.980469 17.929688 C 1527.578125 17.929688 1598.929688 122.640625 1598.929688 249.800781 L 1598.929688 2908.207031 C 1598.929688 3056.5625 1511.539062 3137.132812 1402.980469 3137.289062 L 234.601562 3138.96875 C 102.199219 3139.15625 38.710938 3029.726562 38.644531 2916.59375 L 37.15625 249.800781 C 37.09375 136.75 92.941406 17.929688 233.113281 17.929688 Z M 233.113281 17.929688 "/>
<path fill-rule="nonzero" fill="rgb(70.117188%, 70.117188%, 70.117188%)" fill-opacity="1" d="M 554.761719 2985.53125 L 1139.511719 2985.53125 C 1145.640625 2985.53125 1150.578125 2990.46875 1150.578125 2996.605469 L 1150.578125 3007.621094 C 1150.578125 3013.757812 1145.640625 3018.695312 1139.511719 3018.695312 L 554.761719 3018.695312 C 548.628906 3018.695312 543.6875 3013.757812 543.6875 3007.621094 L 543.6875 2996.605469 C 543.6875 2990.46875 548.628906 2985.53125 554.761719 2985.53125 Z M 554.761719 2985.53125 "/>
</svg>

<div className="video-container_iphone ">
        <video
          className="iphone-video"
          src={value}
          loop
          muted
          autoPlay
          playsInline
        ></video>
      </div>
    </div>
  );
};

export default IphoneSVG;