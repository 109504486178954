import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  motion,
  useTransform,
  useViewportScroll,
  AnimatePresence,
} from "framer-motion";
import { MdArrowOutward } from "react-icons/md";
import Splashscreen from "../Splashscreen/Splashscreen";
import mac from "../Assert/mac.png";
import { PopupContext } from "../Popup/Popupcontext";
import IphoneSVG from "./iphone";
import Magnetic from "../Framermotion/Magentic";
import { Helmet } from "react-helmet-async";
import "./detail.css";

const Detail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const { currentLanguage } = useContext(PopupContext);
  const { scrollYProgress } = useViewportScroll();
  const [showMoreTools, setShowMoreTools] = useState(false);
  const [showMoreDeployment, setShowMoreDeployment] = useState(false);
  const [showMoreYear, setShowMoreYear] = useState(false);

  const translateY = useTransform(scrollYProgress, [0, 1], [-100, 100]);
  const y = useTransform(scrollYProgress, [0, 1], [20, -100]);
  const z = useTransform(scrollYProgress, [0, 1], [100, -100]);

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);

  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(parseInt(id));
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const imageRef3 = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsVisible(true); // Set isVisible to true after loading
    }, 1000);
  }, [currentIndex]);

  useEffect(() => {
    const index = parseInt(id);
    if (
      !isNaN(index) &&
      index >= 0 &&
      index < currentLanguage.projects1.length
    ) {
      setCurrentIndex(index);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
    }
  }, [id, navigate, currentLanguage]);

  const handleNextProject = () => {
    setIsLoading(true);
    const nextIndex = currentIndex + 1;
    if (nextIndex < currentLanguage.projects1.length) {
      navigate(`/Projects/detail/${nextIndex}`);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/");
    }
  };

  const item = currentLanguage.projects1[currentIndex];

  const [videoSrc, setVideoSrc] = useState(item.mac); // Default to desktop video

  const Detail1 = () => {
    const videoRef1 = useRef(null); // Reference for the video element
    const [videoSrc, setVideoSrc] = useState(item.mac); // Default to desktop video

    // Dynamically set video source based on screen width
    useEffect(() => {
      const updateVideoSource = () => {
        if (window.innerWidth <= 768) {
          setVideoSrc(item.mac_min); // Use mobile video for smaller screens
        } else {
          setVideoSrc(item.mac); // Use desktop video for larger screens
        }
      };

      // Call the function on component mount
      updateVideoSource();

      // Add a listener to update video on window resize
      window.addEventListener("resize", updateVideoSource);

      // Clean up the listener when the component is unmounted
      return () => window.removeEventListener("resize", updateVideoSource);
    }, [item.mac, item.mac_mobile]);
  };

  if (!item) {
    return <div>Item not found</div>;
  }

  return (
    <AnimatePresence>
      {isLoading ? (
        <Splashscreen key="splashscreen" words={[item.heading]}></Splashscreen>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isVisible ? 1 : 0 }} 
          transition={{ duration: 1 }}
          className="flex flex-col items-center justify-center mx-auto   bg-[#E5E4E2] overflow-x-hidden "
        >
          <motion.div className="relative flex flex-col justify-center p-2 mt-10 container">
            <motion.div className="text-left justify-left items-left flex text-3xl">
              <motion.h1
                initial={{ y: 70 }}
                animate={{ y: 0 }}
                transition={{ duration: 2 }}
                className="text-left h1 overflow-hidden"
              >
                {item.heading}
              </motion.h1>
            </motion.div>
          </motion.div>
          <Helmet>
            <title>{item.heading}</title>
          </Helmet>

          <div className="flex md:flex-row justify-center p-2 md:gap-7 gap-3 mb-20 flex-col porject-details overflow-hidden container">
            {/* Tools Section */}
            <motion.div
              initial={{ y: 70 }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }}
              className="flex flex-col md:mt-10 mt-8 h-auto w-full"
            >
              <p className="text-left py-5 text-gray-500">01</p>
              <div className="h-[0.5px] bg-gray-500 w-full"></div>
              <h1 className="md:py-8 py-3 text-xl font-semibold">Tools used</h1>

              <p
                className={`text-left text-gray-700 ${
                  !showMoreTools
                    ? "overflow-hidden text-ellipsis line-clamp-3"
                    : ""
                }`}
              >
                {item.tools}
              </p>
              <button
                onClick={() => setShowMoreTools(!showMoreTools)}
                className="text-gray-500  mt-2"
              >
                {showMoreTools ? "Show Less" : "Show More"}
              </button>
            </motion.div>

            {/* Status Section */}
            <motion.div
              initial={{ y: 70 }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }}
              className="flex flex-col md:mt-10 mt-8 w-full"
            >
              <p className="text-left py-5 text-gray-500">02</p>
              <div className="h-[0.5px] bg-gray-500 w-[80%]"></div>
              <h1 className="flex py-2 md:py-8 font-semibold">Status</h1>

              <p
                className={`text-left ${
                  !showMoreDeployment
                    ? "overflow-hidden text-ellipsis line-clamp-3"
                    : ""
                }`}
              >
                {item.deployment}
              </p>
              <button
                onClick={() => setShowMoreDeployment(!showMoreDeployment)}
                className="text-gray-500  mt-2"
              >
                {showMoreDeployment ? "Show Less" : "Show More"}
              </button>
            </motion.div>

            {/* Launched Section */}
            <motion.div
              initial={{ y: 70 }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }}
              className="flex flex-col md:mt-10 mt-8 w-full"
            >
              <p className="text-left py-5 text-gray-500">03</p>
              <div className="h-[0.5px] bg-gray-500 w-[80%]"></div>
              <h1 className="flex py-2 md:py-8 font-semibold">Launched</h1>

              <p
                className={`text-left ${
                  !showMoreYear
                    ? "overflow-hidden text-ellipsis line-clamp-3"
                    : ""
                }`}
              >
                {item.year}
              </p>
              <button
                onClick={() => setShowMoreYear(!showMoreYear)}
                className="text-gray-500  mt-2"
              >
                {showMoreYear ? "Show Less" : "Show More"}
              </button>
            </motion.div>
          </div>
          
          {item.main_image && (
            <motion.div
              initial={{ y: 70 }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }}
              className="relative flex flex-col items-center md:mt-16 mt-10"
            >
              <div className="relative">
                <div>
                  <motion.div
                    className="absolute right-[20px] bg-[#334BD3] z-[10] text-white rounded-full md:h-[140px] md:w-[140px] h-[80px] w-[80px] flex items-center justify-center"
                    style={{ y: translateY }}
                    >
                    <a href={item.link} target="blank">
                      <motion.p className="flex items-center text-2xl ">
                      visit
                        <MdArrowOutward className="text-white rotate-12 ml-2" />
                        </motion.p>
                        </a>

                  </motion.div>
                </div>

                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={item.main_image_mobile}
                  />
                  <source media="(min-width: 769px)" srcSet={item.main_image} />
                  <img
                    src={item.main_image}
                    alt="Dynamic Image"
                    className="mx-auto h-1/2 w-[1200px]"
                    style={{ y }} 
                  />
                </picture>
              </div>
            </motion.div>
          )}

          {/* mac animation  */}
          <div className="p-5 md:p-20">
            {item.mac && (
              <motion.div className="device md:mb-36 md:mt-36 mt-16 mb-16">
                <div className="video-container">
                  {/* Dynamic video source */}
                  <video
                    ref={videoRef1}
                    src={videoSrc} // Dynamically updated video source
                    loop
                    muted
                    autoPlay
                    playsInline
                    className="video-element"
                  ></video>
                </div>
                <img src={mac} alt="Device" className="overlay-image" />
              </motion.div>
            )}
          </div>
          {/* video playing second play
          <div className="  relative md:mb-36 mb-16">
            <video ref={videoRef2} loop playsInline muted autoPlay>
              <source src={item.mac} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div> */}

          {/* personal pic  */}
          {item.personal && (
            <motion.div
              initial={{ opacity: 0, y: 70 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              className="relative flex flex-col items-center md:mt-16 mt-10"
            >
              <div >
                {/* Add a gradient background for the shade effect */}

                <motion.picture>
                  <motion.source
                    media="(max-width: 768px)"
                    srcSet={item.personal_mobile}
                  />
                  <motion.source
                    media="(min-width: 769px)"
                    srcSet={item.personal}
                  />
                  <motion.img
                    src={item.personal}
                    alt="Dynamic Image"
                    className="mx-auto w-[80%] object-cover rounded-lg" 
                    style={{ y }} // Scroll-based animation
                    whileHover={{ scale: 1.05 }} // Scale effect on hover
                    transition={{ duration: 0.5, ease: "easeOut" }}
                  />
                </motion.picture>
              </div>
            </motion.div>
          )}

          <IphoneSVG value={item.mobile} />

          <motion.div
            className="flex flex-row md:gap-20   md:mt-60 md:mb-20 mb-7"
            ref={containerRef}
          >
            {item.mobile2 && (
              <motion.div ref={imageRef1}>
                <motion.picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={item.mobile1_min}
                  />
                  <source media="(min-width: 769px)" srcSet={item.mobile1} />
                  <img
                    src={item.mobile1}
                    alt="Dynamic Image"
                    className="md:h-[500px] vsm:[100px] rounded-2xl  md:flex hidden"
                    style={{ y }} // This is for motion animation
                  />
                </motion.picture>
              </motion.div>
            )}
            {item.mobile2 && (
              <motion.div>
                <motion.picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={item.mobile2_min}
                  />
                  <source media="(min-width: 769px)" srcSet={item.mobile2} />
                  <img
                    src={item.mobile2}
                    alt="Dynamic Image"
                    className="md:h-[500px] vsm:[100px] rounded-2xl  md:flex hidden"
                    style={{ y }} // This is for motion animation
                  />
                </motion.picture>
              </motion.div>
            )}
            {item.mobile1 && (
              <motion.div ref={imageRef2}>
                <motion.picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={item.mobile3_min}
                  />
                  <source media="(min-width: 769px)" srcSet={item.mobile3} />
                  <img
                    src={item.mobile3}
                    alt="Dynamic Image"
                    className="md:h-[500px] vsm:[100px] rounded-2xl  md:flex hidden"
                    style={{ y }} // This is for motion animation
                  />
                </motion.picture>
              </motion.div>
              )}
              
              
            </motion.div>
            
          <motion.div className="highlight-text start-aligned container line-clamp-4">
        <h3 className="highlight-title">{currentLanguage.note}</h3>
              <div className="flex flex-col gap-3 md:gap-5">
                <p className="highlight-description ">{item.note1}</p>
              <p className="highlight-description">{item.note2} </p>
                <p className="highlight-description">{item.note3}</p>

            </div>
      </motion.div>
          <Magnetic>
            <button
              className= " mt-10 mb-20 text-center text-xl hover:opacity-100 transition-opacity sm:rounded-full md:rounded-full px-10 py-4 md:w-[400px] sm:h-[30px] sm:w-[100px] justify-center flex items-center border hover:bg-gray-800 hover:text-white border-black opacity-50"
              onClick={handleNextProject}
            >
              {currentIndex === currentLanguage.projects1.length - 1
                ? "Go to Home"
                : "Next Project"}
              <MdArrowOutward />
            </button>
          </Magnetic>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Detail;
