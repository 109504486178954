import React, { useContext } from "react";
import { PopupContext } from "../Popup/Popupcontext";
import { useNavigate } from "react-router-dom";
function Pagenotfound() {
  const { currentLanguage } = useContext(PopupContext);
  const navigate = useNavigate(); 
  const goToHomePage = () => {
    navigate("/");
  };
  return (
    <div className="flex flex-col items-center justify-center mx-auto my-auto h-screen bg-[#FFFF] w-screen">
      <div>
        <p className="md:text-[320px] text-[120px] font-wixmadefor text-center">
          404
        </p>
      </div>
      <div className="flex justify-center text-center gap-4 font-wixmadefor text-2xl">
        <p>{currentLanguage.page_not}</p>
      </div>
      <button
        className="border border-red-100 bg-violet-600 text-white rounded-xl md:p-5 p-2 mt-10 hover:bg-red-300 "
        onClick={goToHomePage}
      >
        Go to main page
      </button>
    </div>
  );
}
export default Pagenotfound;
