import React, { useContext } from 'react';
import { motion } from "framer-motion";
import { PopupContext } from '../Popup/Popupcontext';
function TextAbout({ isVisible }) {  
  const {currentLanguage } = useContext(PopupContext);
  return (
    <div>
       <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: isVisible ? 1 : 0 }} 
      transition={{ duration: 1 }}
      className="flex md:flex-col flex-col text-center mt-10"
    >
      <motion.p
        initial={{ y: 70 }}
        animate={{ y: 0 }}
        transition={{ duration: 2 }}
        className="max-w-[500px]  font-ptsansr"
        style={{lineHeight:1.8}}
      >
        <strong>{currentLanguage.aboutme}</strong><br /><br />
        {currentLanguage.About_about_name} <b className='text-custom-indigo'>26</b> {currentLanguage.About_about_year} <b className='text-custom-indigo'>2017</b>, {currentLanguage.About_about_work} <b className='text-custom-rose-wood'>5 {currentLanguage.years}</b> {currentLanguage.About_about_work2}
      </motion.p>
      <motion.p
        initial={{ y: 70 }}
        animate={{ y: 0 }}
        transition={{ duration: 2 }}
        className="max-w-[500px] mt-10 font-ptsansr "
        style={{lineHeight:1.8}}
      >
        <strong>{currentLanguage.About_about_parttime}</strong><br /><br />
        { currentLanguage.About_about_parttime_head}<b className='text-custom-blue'> 200,000 {currentLanguage.km},</b> {currentLanguage.moutains} <b className=' text-green-800'>80</b> {currentLanguage.mountains_data}
      </motion.p>
      <motion.p
        initial={{ y: 70 }}
        animate={{ y: 0 }}
        transition={{ duration: 2 }}
        className="max-w-[500px] mt-10 font-ptsansr"
        style={{lineHeight:1.9}}
      >
        <strong>{currentLanguage.About_about_Hobbies}</strong><br /><br />
        {currentLanguage.About_about_Hobbies1} <b className=' text-custom-rose-wood'>30%</b>.
      </motion.p>
      <motion.p
        initial={{ y: 70 }}
        animate={{ y: 0 }}
        transition={{ duration: 2 }}
        className="max-w-[500px] mt-10 font-ptsansr"
        style={{lineHeight:1.9}}
      >
        <strong>{currentLanguage.About_about_Languages}s</strong><br /><br />
        {currentLanguage.About_about_Languages1}<b className=' text-custom-green'> 70%</b> {currentLanguage.About_about_Languages2}<b className=' text-custom-lapliz'> 40%</b>.{currentLanguage.About_about_Languages3}
      </motion.p>
    </motion.div>
   </div>
  );
}

export default TextAbout;