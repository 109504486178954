import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import emailjs from "emailjs-com"; 
import footbar from "../Assert/footbar.png";
import Magnetic from "../Framermotion/Magentic"
import Splashscreen from "../Splashscreen/Splashscreen";
import './style.css';
import { PopupContext } from "../Popup/Popupcontext";
import { Helmet } from "react-helmet-async";

function Contact() {
  const [isLoading, setIsLoading] = useState(true);
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    organization: "",
    looking: "",
    message: "",
  });
  const { currentLanguage } = useContext(PopupContext)
  const [errors, setErrors] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isCooldown, setIsCooldown] = useState(false); 
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true); 
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);
  const sanitizeInput = (input) => {
    return input.replace(/<\/?[^>]+>/gi, "");
  };
  const popupVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };
  const validateData = () => {
    const sanitizedInputs = {
      name: sanitizeInput(inputs.name),
      email: sanitizeInput(inputs.email),
      organization: sanitizeInput(inputs.organization),
      looking: sanitizeInput(inputs.looking),
      message: sanitizeInput(inputs.message),
    };
    const newErrors = {};
    if (!sanitizedInputs.name) newErrors.name = "Name is required";
    if (!sanitizedInputs.email) newErrors.email = "Email is required";
    if (!sanitizedInputs.organization)
      newErrors.organization = "Organization name is required";
    if (!sanitizedInputs.looking)
      newErrors.looking = "Service description is required";
    if (!sanitizedInputs.message) newErrors.message = "Message is required";
    return { errors: newErrors, sanitizedInputs };
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isCooldown) return; 
    const { errors: validationErrors, sanitizedInputs } = validateData();
    if (Object.keys(validationErrors).length === 0) {
      setIsCooldown(true); // Start cooldown
      emailjs
        .sendForm(
          "service_l4id84o",
          "template_47oj8nh",
          event.target,
          "_CvoNyTl0Or-V0ysl"
        )
        .then(
          (result) => {
            setInputs({
              name: "",
              email: "",
              organization: "",
              looking: "",
              message: "",
            });
            setErrors({});
            setMessage("Message sent successfully!");
            setMessageType("success");
            setTimeout(() => {
              setMessage(""); // Clear message after 5 seconds
            }, 5000);
          },
          (error) => {
            setMessage("There was an error sending your message.");
            setMessageType("error");
            setTimeout(() => {
              setMessage(""); // Clear message after 5 seconds
            }, 5000);
          }
        )
        .finally(() => {
          setTimeout(() => {
            setIsCooldown(false); // Re-enable button after 30 seconds
          }, 30000); // 30-second cooldown
        });
    } else {
      setErrors(validationErrors);
    }
  };
  return (
    <AnimatePresence>
      {isLoading ? (
        <Splashscreen key="splashscreen" words={[currentLanguage.contact]} />
      ) : (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isVisible ? 1 : 0 }} // Animate based on isVisible
            transition={{ duration: 1 }}
            className="flex flex-row gap-20 justify-center items-center md:h-[190vh] text-white bg-[#1C1D20]"
          >
              <Helmet>
        <title>{currentLanguage.contact} </title>
        <meta name="description" content="Learn more about Your Website and our mission." />
        <meta name="keywords" content="about, mission, team" />
      </Helmet>
            <motion.div
              initial={{ y: 70 }}
              animate={{ y: 0 }}
              transition={{ duration: 2 }}
              className="flex justify-center items-center md:mt-8 sm:mt-28"
            >
            <motion.div  initial={{ y: 70 }}
            animate={{ y: 0 }}
            transition={{ duration: 2 }}  className="flex items-center justify-between gap-20">
              <motion.div className="flex flex-col gap-6">
                <motion.div
                  initial={{ y: 70 }}
                  animate={{ y: 0 }}
                  transition={{ duration: 2 }}
                  className="md:m-12 m-8 flex flex-col gap-5"
                >
                    <h1 className="md:text-8xl sm:text-8xl vsm:text-5xl">
                      {currentLanguage.contact_heading}

                    </h1>
                  <h1 className="md:text-8xl sm:text-8xl vsm:text-5xl">
                    {currentLanguage.contact_heading1}
                  </h1>
                  <div className="flex-col text-lg md:mb-20 md:ml-20 md:mt-20 sm:mt-14 vsm:mt-10 md:hidden">
                    <img
                      src={footbar}
                      alt=""
                      className="md:w-28 md:h-28 sm:h-25 sm:w-25 vsm:h-20 vsm:w-20"
                    />
                    <div className="md:mt-10 mt-6 flex flex-col md:gap-4 gap-2 md:text-xl sm:text-lg text-sm">
                      <p className="text-[#5C5859] font-bold md:text-xl">
                        {currentLanguage.contact_details}
                      </p>
                      <a href="mailto:nshanmukh52@gmail.com">
                        nshanmukh52@gmail.com
                      </a>
                      <a href="tel:+918885522676">+918885522676</a>
                    </div>
                    <div className="mt-10 flex flex-col md:gap-3 gap-2 md:text-xl sm:text-lg text-sm">
                      <p className="text-[#5C5859] font-bold md:text-xl vsm:text-lg">
                        {currentLanguage.business_details}
                      </p>
                      <a
                        target="blank"
                        href="https://www.google.co.in/maps/place/Visakhapatnam,+Andhra+Pradesh/@17.7386034,83.0977797,11z/data=!3m1!4b1!4m6!3m5!1s0x3a39431389e6973f:0x92d9c20395498468!8m2!3d17.6868159!4d83.2184815!16zL20vMDFqbXBm?entry=ttu"
                      >
                        {currentLanguage.pincode}: 535002
                      </a>
                      <a
                        target="blank"
                        href="https://www.google.co.in/maps/place/India/@20.7360632,72.1002822,5z/data=!3m1!4b1!4m6!3m5!1s0x30635ff06b92b791:0xd78c4fa1854213a6!8m2!3d20.593684!4d78.96288!16zL20vMDNyazA?entry=ttu"
                      >
                        {currentLanguage.location}:{currentLanguage.india}
                      </a>
                    </div>
                    <div className="mt-10 flex flex-col gap-2 md:text-xl sm:text-lg text-sm">
                      <p className="text-[#5C5859] font-bold text-xl">{currentLanguage.location}</p>
                      <a href="mailto:nshanmukh52@gmail.com">{currentLanguage.LinkedIn}</a>
                      <a href="tel:+918885522676">{currentLanguage.Whatsapp}</a>
                      <a
                        target="blank"
                        href="https://www.instagram.com/nandhi._/"
                      >
                        {currentLanguage.Instagram}
                      </a>
                    </div>
                  </div>
                </motion.div>
                <form
                  className="flex flex-col gap-3 md:mt-10"
                  onSubmit={handleSubmit}
                >
                  <div className="md:w-full h-[0.5px] bg-gray-500"></div>
                  <label>
                    <div className="flex sm:p-8 vsm:p-6">
                      <p className="md:mr-20 sm:mr-20 mr-4 text-[#9C9898] md:text-xl">
                        01
                      </p>
                      <div className="flex flex-col gap-3">
                        <p className="md:text-3xl sm:text-2xl word-spacing-3">
                          {currentLanguage.contact_your_name}
                        </p>
                        <input
                          type="text"
                          name="name"
                          placeholder={currentLanguage.contact_your_nametag}
                          className="bg-transparent outline-none md:text-2xl sm:text-2xl"
                          value={inputs.name}
                          onChange={handleChange}
                          required
                        />
                        {errors.name && (
                          <p className="text-red-500">{errors.name}</p>
                        )}
                      </div>
                    </div>
                  </label>
                  <div className="w-full h-[0.5px] bg-gray-500"></div>
                  <label>
                    <div className="flex sm:p-8 vsm:p-6">
                      <p className="md:mr-20 mr-4  text-[#9C9898] md:text-xl">
                        02
                      </p>
                      <div className="flex flex-col gap-3">
                        <p className="md:text-3xl sm:text-2xl word-spacing-3">
                          {currentLanguage.contact_your_email}
                        </p>
                        <input
                          type="email"
                          name="email"
                          placeholder="nshanmukh52@gmail.com*"
                          className="bg-transparent outline-none md:text-2xl sm:text-2xl w-full"
                          value={inputs.email}
                          onChange={handleChange}
                          required
                        />
                        {errors.email && (
                          <p className="text-red-500">{errors.email}</p>
                        )}
                      </div>
                    </div>
                  </label>
                  <div className="w-full h-[0.5px] bg-gray-500"></div>
                  <label>
                    <div className="flex sm:p-8 vsm:p-6">
                      <p className="md:mr-20 mr-4  text-[#9C9898] md:text-xl">
                        03
                      </p>
                      <div className="flex flex-col gap-3">
                        <p className="md:text-3xl sm:text-2xl word-spacing-3">
                         {currentLanguage.contact_your_org}
                        </p>
                        <input
                          type="text"
                          name="organization"
                          placeholder={currentLanguage.contact_your_orgtag}
                          className="bg-transparent outline-none md:text-2xl sm:text-2xl"
                          value={inputs.organization}
                          onChange={handleChange}
                          required
                        />
                        {errors.organization && (
                          <p className="text-red-500">{errors.organization}</p>
                        )}
                      </div>
                    </div>
                  </label>
                  <div className="w-full h-[0.5px] bg-gray-500"></div>
                  <label>
                    <div className="flex sm:p-8 vsm:p-6">
                      <p className="md:mr-20 mr-4  text-[#9C9898] md:text-xl">
                        04
                      </p>
                      <div className="flex flex-col gap-3">
                        <p className="md:text-3xl sm:text-2xl word-spacing-3">
                         {currentLanguage.contact_your_looking}
                        </p>
                        <input
                          type="text"
                          name="looking"
                          placeholder={currentLanguage.contact_your_lookingtag}
                          className="bg-transparent outline-none md:text-2xl sm:text-2xl"
                          value={inputs.looking}
                          onChange={handleChange}
                          required
                        />
                        {errors.looking && (
                          <p className="text-red-500">{errors.looking}</p>
                        )}
                      </div>
                    </div>
                  </label>
                  <div className="w-full h-[0.5px] bg-gray-500"></div>
                  <label>
                    <div className="flex sm:p-8 vsm:p-6">
                      <p className="md:mr-20 mr-4   text-[#9C9898] md:text-xl">
                        05
                      </p>
                      <div className="flex flex-col gap-3">
                          <p className="md:text-3xl sm:text-2xl word-spacing-3">
                          {currentLanguage.contact_your_message}
                          </p>
                        <textarea
                          name="message"
                          placeholder={currentLanguage.contact_your_messagetag}
                          className="bg-transparent outline-none md:text-2xl sm:text-2xl"
                          value={inputs.message}
                          onChange={handleChange}
                          required
                        />
                        {errors.message && (
                          <p className="text-red-500">{errors.message}</p>
                        )}
                      </div>
                    </div>
                  </label>
                  <div className="w-full h-[0.5px] bg-gray-500"></div>
                  <Magnetic>
                    <motion.div
                      className="md:w-full md:h-16 sm:h-3 sm:w-full  w-full  h-12
                rounded-xl flex justify-center items-center
               border-2 border-white hover:border-0 cursor-pointer md:mt-20 mt-10 mb-10
               hover:bg-[#455CE9] bg-[#455CE9] hover:text-black  shine-effect"
                    >
                      <motion.button
                        type="submit"
                        className="md:p-10  md:text-3xl text-xl font-wixmadefor "
                          initial={{ x: 0, y: 0 }}
                          disabled={isCooldown} 
                      >
                        {currentLanguage.contact_button}
                      </motion.button>
                    </motion.div>
                  </Magnetic>
                  <AnimatePresence>
                    {message && (
                      <motion.div
                        variants={popupVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                        className={`mt-4 p-4 text-white ${
                          messageType === "success"
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        {message}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </form>
              </motion.div>
              <div className="hidden md:flex flex-col gap-8">
                <img src={footbar} alt="" className="w-28 h-28" />
                <div className="flex flex-col gap-4 text-xl">
                    <p className="text-[#5C5859] font-bold">{currentLanguage.business_details}</p>
                  <a href="mailto:nshanmukh52@gmail.com">
                    nshanmukh52@gmail.com
                  </a>
                  <a href="tel:+918885522676">+918885522676</a>
                </div>
                <div className="flex flex-col gap-3 text-xl">
                    <p className="text-[#5C5859] font-bold">{currentLanguage.business_details}</p>
                  <a
                    target="blank"
                    href="https://www.google.co.in/maps/place/Visakhapatnam,+Andhra+Pradesh/@17.7386034,83.0977797,11z/data=!3m1!4b1!4m6!3m5!1s0x3a39431389e6973f:0x92d9c20395498468!8m2!3d17.6868159!4d83.2184815!16zL20vMDFqbXBm?entry=ttu"
                  >
                    {currentLanguage.pincode}: 535002
                  </a>
                  <a
                    target="blank"
                    href="https://www.google.co.in/maps/place/India/@20.7360632,72.1002822,5z/data=!3m1!4b1!4m6!3m5!1s0x30635ff06b92b791:0xd78c4fa1854213a6!8m2!3d20.593684!4d78.96288!16zL20vMDNyazA?entry=ttu"
                  >
                    {currentLanguage.location}:{currentLanguage.india}
                  </a>
                </div>
                <div className="flex flex-col gap-3 text-xl">
                  <p className="text-[#5C5859] font-bold">{currentLanguage.social}</p>
                  <a href="mailto:nshanmukh52@gmail.com">{currentLanguage.LinkedIn}</a>
                  <a href="tel:+918885522676">{currentLanguage.Whatsapp}</a>
                  <a target="blank" href="https://www.instagram.com/nandhi._/">
                    {currentLanguage.Instagram}
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
export default Contact;
