import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Magnetic from '../Framermotion/Magentic';
import Linker from '../Third_page/Link_data';
import { PopupContext } from '../Popup/Popupcontext';

function Porjectsim() {
  const {currentLanguage} = useContext(PopupContext)
  currentLanguage.projects1.forEach((project, index) => {
  });
  return (
    <section className="bg-white p-4 md:p-8 text-center mt-10 sm:mt-22 md:mt-32 flex flex-col justify-center">
      <div className="mx-auto container">
        <h1 className='text-left mb-10 font-wixmadefor md:text-5xl text-3xl font-bold'>Projects</h1>
        <div className="h-[0.5px] w-full bg-gray-900 container flex justify-center items-center text-center"></div>
      
        {currentLanguage.projects1.map((project, index) => (
          <Link
            key={index}
            to={`/Projects/detail/${index}`} // Dynamic route based on index
            className="block mb-4 text-blue-500 hover:text-blue-700"
          >
            <Linker
              heading={project.heading}        // Passing project heading
              subheading={project.subheading}  // Passing project subheading
              imgSrc={project.imgSrc}          // Passing project image source
              href={`/Projects/detail/${index}`} // Dynamic URL
            />
          </Link>
        ))}
        {/* we need to change the value create a projects page it will take one day */}
        <Magnetic>
          <Link to="/Projects">    
          <button className="border border-gray-500 md:py-8 md:px-14 py-3 px-4 rounded-[60px] mt-10 md:text-2xl font-semibold hover:bg-[#455CE9] hover:text-white">
            {currentLanguage.view_more} <sup>0</sup>
            </button>
            </Link>
        </Magnetic>
      </div>
    </section>
  )
}

export default Porjectsim