import React, { createContext, useState, useEffect } from "react";
import { languages } from "../Assert/langugae.js"; 
export const PopupContext = createContext();
export const PopupProvider = ({ children }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupFormData, setPopupFormData] = useState(null); 
  const [selectedLanguage, setSelectedLanguage] = useState("en"); 
  const showPopup = () => {
    setIsPopupVisible(true);
  };
  const hidePopup = () => {
    setIsPopupVisible(false);
  };
  const handlePopupComplete = (data) => {
    if (data && data.language) {
      setPopupFormData(data);
      setSelectedLanguage(data.language); 
      localStorage.setItem("selectedLanguage", data.language); 
    } else {
    }
    hidePopup();
    localStorage.setItem("popupCompleted", "true"); 
  };
  useEffect(() => {
    try {
      const popupCompleted = localStorage.getItem("popupCompleted");
      const savedLanguage = localStorage.getItem("selectedLanguage");
      if (savedLanguage) {
        setSelectedLanguage(savedLanguage); 
      }
      if (!popupCompleted) {
        showPopup(); 
      }
    } catch (error) {
    }
  }, []);
  const currentLanguage = languages[selectedLanguage] || languages.en;
  return (
    <PopupContext.Provider
      value={{
        isPopupVisible,
        showPopup,
        hidePopup,
        handlePopupComplete,
        popupFormData,
        currentLanguage, 
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};
