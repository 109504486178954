import React, { useContext, useState, useEffect } from "react";
import { PopupContext } from "../Popup/Popupcontext";
const HoverNameChange = () => {
  const { currentLanguage } = useContext(PopupContext);
  const [isHovered, setIsHovered] = useState(false);
  const [name, setName] = useState(currentLanguage.nav_name);
  useEffect(() => {
    setName(currentLanguage.nav_name); // Update name when language changes
  }, [currentLanguage]);
  const handleMouseEnter = () => {
    setIsHovered(true);
    setName(currentLanguage.nav_change); // Show nav_change when hovered
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    setName(currentLanguage.nav_name); // Revert back to nav_name when the cursor leaves
  };
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <p className="transition-all duration-100 md:text-5xl text-3xl">{name}</p>
    </div>
  );
};
export default HoverNameChange;
