import React from 'react'
import { useState, useRef ,useId} from 'react';
function Linear() {
    const id = useId();
    const ageRef = useRef(null);
    const bmiRef = useRef(null);
    const childrenRef = useRef(null);
    const genderRef = useRef(null);
    const smokingRef = useRef(null);
    const regionRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
      age: '',
      bmi: '',
      children: '',
      gender: '',
      smoking: '',
      region: '',
    });
    
  
    const [result, setResult] = useState(null);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const age = Number(ageRef.current.value);
    const bmi = Number(bmiRef.current.value);
    const children = Number(childrenRef.current.value);
  
      const newErrors = {
        age: '',
        bmi: '',
        children: '',
        gender: '',
        smoking: '',
        region: '',
      };
  
      // Validate required fields
      if (!age || age < 18 || age > 60) {
        newErrors.age = "Age must be between 18 and 60.";
      }
      if (!bmi || bmi <= 0 || bmi >= 40) {
        newErrors.bmi = "BMI must be greater than 0 and less than 40.";
      }
      if (!children || children < 0 || children >= 10) {
        newErrors.children = "Children must be a non-negative number less than 10.";
      }
      if (!genderRef.current.value) newErrors.gender = 'Gender is required';
      if (!smokingRef.current.value) newErrors.smoking = 'Smoking status is required';
      if (!regionRef.current.value) newErrors.region = 'Region is required';
  
      setErrors(newErrors);
  
      const hasErrors = Object.values(newErrors).some((error) => error !== '');
      if (!hasErrors) {
        const age = ageRef.current.value;
        const bmi = bmiRef.current.value;
        const children = childrenRef.current.value;
        const gender = genderRef.current.value;
        const smoking = smokingRef.current.value;
        const region = regionRef.current.value;
  
        // Map the gender fields
        const sex_male = gender === 'male' ? 1 : 0;
        const sex_female = gender === 'female' ? 1 : 0;
  
        // Map the smoking fields
        const smoker_yes = smoking === 'yes' ? 1 : 0;
        const smoker_no = smoking === 'no' ? 1 : 0;
  
        // Map the region fields
        const region_northwest = region === 'northwest' ? 1 : 0;
        const region_southeast = region === 'southeast' ? 1 : 0;
        const region_southwest = region === 'southwest' ? 1 : 0;
        const region_northeast = region === 'northeast' ? 1 : 0;
        setLoading(true)
  
        try {
          // POST request
          const postResponse = await fetch(
            `https://api.nandhieswar.site/predict/${id}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                age: Number(age),
                bmi: Number(bmi),
                children: Number(children),
                sex_male,
                sex_female,
                smoker_yes,
                smoker_no,
                region_northwest,
                region_southeast,
                region_southwest,
                region_northeast,
              }),
            }
          );
  
          if (postResponse.ok) {
            // GET request to fetch the value
            const getResponse = await fetch(
              `https://api.nandhieswar.site/return_value/${id}`
            );
            if (getResponse.ok) {
              const data = await getResponse.json();
              setResult(data); 
              setShowPopup(true);
            } else {
            }
          } else {
          }
        } catch (error) {
        }finally {
          setLoading(false); // Stop loading
        }
      }
    };
  
    return (
      <div className="container mx-auto py-10">
        <form
          className="bg-gray-100 p-6 rounded-lg shadow-md space-y-4"
          onSubmit={handleSubmit}
        >
          {/* Form Fields */}
          <div className="flex flex-col">
            <label className="text-gray-700 font-medium">Age</label>
            <input
              type="number"
              placeholder="18+"
              ref={ageRef}
              className="border border-gray-300 rounded-md px-4 py-2 mt-1 focus:outline-none focus:ring focus:ring-indigo-300"
            />
            {errors.age && <small className="text-red-500 mt-1">{errors.age}</small>}
          </div>
  
          <div className="flex flex-col">
            <label className="text-gray-700 font-medium">BMI</label>
            <input
              type="number"
              step="0.001"
              placeholder="23.56"
              ref={bmiRef}
              className="border border-gray-300 rounded-md px-4 py-2 mt-1 focus:outline-none focus:ring focus:ring-indigo-300"
            />
            {errors.bmi && <small className="text-red-500 mt-1">{errors.bmi}</small>}
          </div>
  
          <div className="flex flex-col">
            <label className="text-gray-700 font-medium">Children</label>
            <input
              type="number"
              placeholder="<4"

              ref={childrenRef}
              className="border border-gray-300 rounded-md px-4 py-2 mt-1 focus:outline-none focus:ring focus:ring-indigo-300"
            />
            {errors.children && (
              <small className="text-red-500 mt-1">{errors.children}</small>
            )}
          </div>
          {loading && (
        <div className="flex justify-center items-center mt-4">
          <div className="loader border-t-4 border-indigo-500 border-solid rounded-full w-10 h-10 animate-spin z-30"></div>
          <p className="ml-3 text-indigo-700">Loading...</p>
        </div>
      )}
  
          {/* Dropdowns */}
          <div className="flex flex-col">
            <label className="text-gray-700 font-medium">Select your gender</label>
            <select
              ref={genderRef}
              className="border border-gray-300 rounded-md px-4 py-2 mt-1 focus:outline-none focus:ring focus:ring-indigo-300"
            >
              <option value="">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            {errors.gender && (
              <small className="text-red-500 mt-1">{errors.gender}</small>
            )}
          </div>
  
          <div className="flex flex-col">
            <label className="text-gray-700 font-medium">Smoking</label>
            <select
              ref={smokingRef}
              className="border border-gray-300 rounded-md px-4 py-2 mt-1 focus:outline-none focus:ring focus:ring-indigo-300"
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            {errors.smoking && (
              <small className="text-red-500 mt-1">{errors.smoking}</small>
            )}
          </div>
  
          <div className="flex flex-col">
            <label className="text-gray-700 font-medium">Region</label>
            <select
              ref={regionRef}
              className="border border-gray-300 rounded-md px-4 py-2 mt-1 focus:outline-none focus:ring focus:ring-indigo-300"
            >
              <option value="">Select</option>
              <option value="southwest">Southwest</option>
              <option value="southeast">Southeast</option>
              <option value="northwest">Northwest</option>
              <option value="northeast">Northeast</option>
            </select>
            {errors.region && (
              <small className="text-red-500 mt-1">{errors.region}</small>
            )}
          </div>
  
          <button
            type="submit"
            className="bg-indigo-500 text-white px-4 py-2  w-full rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300"
          >
            Submit
          </button>
        </form>
  
        {/* Display the result */}
        {result && (
          <p className="text-green-600 mt-4 flex justify-center text-center text-xl">Predicted Value: ${result["prediction"]}</p>
        )}
        {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-md text-center">
            <h2 className="text-xl font-semibold mb-4">Prediction Result</h2>
            {result ? (
              <p className="text-lg text-green-600">
                Predicted Value: ${result["prediction"]}
              </p>
            ) : (
              <p className="text-lg text-red-600">Failed to fetch the result.</p>
            )}
            <button
              onClick={() => setShowPopup(false)}
              className="mt-4 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 focus:outline-none"
            >
              Close
            </button>
          </div>
        </div>
      )}
      </div>
    );
  }

export default Linear