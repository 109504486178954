import { motion } from 'framer-motion';
import './imagecontainer.css';

function ImageContainer() {
  return (
    <motion.div
      className="image-container flex items-center justify-center h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.picture>
        <source
          media="(max-width: 768px)"
          srcSet="https://s3.us-east-1.amazonaws.com/nandhieswar.site/about_main.jpg"
        />
        <source
          media="(min-width: 769px)"
          srcSet="https://s3.us-east-1.amazonaws.com/nandhieswar.site/about_main.jpg"
        />
        <img
          src="https://s3.us-east-1.amazonaws.com/nandhieswar.site/about_main.jpg"
          alt="alt"
          className="object-cover w-full h-full"
        />
      </motion.picture>
    </motion.div>
  );
}

export default ImageContainer;
