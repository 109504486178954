import React, { useContext } from "react";
import { useRef } from "react";
import { useScroll, motion, useTransform } from "framer-motion";
import Magnetic from "../Framermotion/Magentic";
import footer from "../Assert/footbar.png";
import "./Fotters.css";
import { Link } from "react-router-dom";
import { PopupContext } from "../Popup/Popupcontext";
function Fotters() {
  const { currentLanguage } = useContext(PopupContext);
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "end end"],
  });
  const x = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const y = useTransform(scrollYProgress, [0, 1], [-500, 0]);
  return (
    <div
      style={{ y }}
      ref={container}
      className="   bg-[#141516] text-white justify-center items-center "
    >
      <div className=" flex flex-col justify-center items-center  gap-10">
        <div className="flex items-center md:gap-4 gap-0 sm:gap-4  md:mt-52 sm:mt-40 mt-20 ">
          <img
            src={footer}
            alt="main fotter"
            className="md:w-[100px] md:h-[100px] sm:w-[80px] sm:h-[80px] h-[60px] w-[60px] ml-5 sm:ml-0 "
          />
          <h2 className="text-center md:text-9xl text-5xl  sm:text-6xl  font-wixmadefor  text-white">
            {/* {currentLanguage.lets_work_together.split("").map((child, idx) => (
             <span className="hoverText" key={idx}>
            {child}
          </span>
        ))}{" "} */}
            {currentLanguage.lets_work_together}
          </h2>
        </div>
        <motion.div
          className=" items-center justify-end w-1/2 md:h-1 h-[0.8px] bg-[#A5A5A5] flex  sm:my-12 container sm:mt-28 
              my-8"
        >
          <Magnetic>
            <Link to={"/Contact"}>
              <motion.div
                style={{ x }}
                className=" bg-[#334BD3] rounded-full md:h-[100px] md:w-[100px] items-center
                   justify-center flex md:p-24   md:mr-28 p-10 sm:h-[150px] sm:w-[150px]  h-[100px] w-[100px] text-center"
              >
                <Magnetic>
                  <motion.p
                    initial={{ x: 0, y: 0 }}
                    className=" md:text-lg sm:text-lg text-sm "
                  >
                    {currentLanguage.get_in_touch}
                  </motion.p>
                </Magnetic>
              </motion.div>
            </Link>
          </Magnetic>
        </motion.div>
        <div className="flex md:gap-20 sm:gap-16 gap-7 sm:mt-10 mt-5 md:flex-row sm:flex-row flex-col ">
          {/* buttons */}
          <Magnetic>
            <motion.div className=" border rounded-xl md:p-5 p-3 items-center flex justify-center  border-[#C3B091] hover:bg-[#455CE9]">
              <a href="mailto:eswar@nandhieswar.site">
                <motion.p initial={{ x: 0, y: 0 }} className="">
                  eswar@nandhieswar.site
                </motion.p>
              </a>
            </motion.div>
          </Magnetic>
          <Magnetic>
            <motion.div className=" border rounded-lg md:p-5  p-3 items-center flex justify-center border-[#C3B091] hover:bg-[#455CE9]">
              <a href="tel:+918885522676">
                <motion.p initial={{ x: 0, y: 0 }}>+91 8885522676</motion.p>
              </a>
            </motion.div>
          </Magnetic>
        </div>
      </div>
      <div
        className=" items-center  w-full md:h-1 h-[0.8px] bg-[#A5A5A5] flex   
              my-8 sm:hidden"
      />
      <div className="flex sm:ml-10 sm:mt-40 sm:flex-row flex-col-reverse  justify-between items-center">
        <div className=" flex  md:gap-20 sm:gap-10 text-sm sm:text-lg md:text-lg  flex-row justify-between  gap-32">
          <span>
            <h3>{currentLanguage.version}</h3>
            <p>2024 © {currentLanguage.edition}</p>
          </span>
          <span>
            <h3>{currentLanguage.version}</h3>
            <p>11:49 PM {currentLanguage.gmt}+2</p>
          </span>
        </div>
        <div
          className=" items-center  w-full md:h-1 h-[0.8px] bg-[#A5A5A5] flex   
              my-8 sm:hidden"
        />
        <div className="flex md:gap-10 sm:gap-2 text-sm sm:text-lg md:text-lg  sm:mr-10 mr-0 felx gap-10 ">
          <Magnetic>
          <a href="https://www.instagram.com/nandhi._/" target="_blank" className="cursor-pointer">
  {currentLanguage.Instagram}
</a>
            
          </Magnetic>
          <Magnetic>
            <a href="https://wa.me/918885522676" target="_balnk" className="cursor-pointer">
            {currentLanguage.WhatsApp}
            </a>
          </Magnetic>
          <Magnetic>
          <a href="https://www.linkedin.com/in/shanmukh-nandhi" target="_balnk" className="cursor-pointer">
          {currentLanguage.LinkedIn}
            </a>
          </Magnetic>
        </div>
      </div>
    </div>
  );
}
export default Fotters;
