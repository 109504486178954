import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Linker from '../Third_page/Link_data';
import { PopupContext } from '../Popup/Popupcontext';

const HoverImageLinks = () => {
  const { currentLanguage } = useContext(PopupContext);
  
 

  return (
    <section className="bg-white p-4 md:p-8 text-center mt-10 sm:mt-22 md:mt-32 flex flex-col justify-center">
      <div className="mx-auto container">
        <h1 className='text-left mb-10 font-wixmadefor md:text-5xl text-3xl font-bold'>Projects</h1>
        <div className="h-[0.5px] w-full bg-gray-900 container flex justify-center items-center text-center"></div>

        {currentLanguage.projects1.map((project, index) => (
          <Link
            key={index}
            to={`/Projects/detail/${index}`} 
            className="block mb-4 text-blue-500 hover:text-blue-700"
          >
            <Linker
              heading={project.heading}        
              subheading={project.subheading}  
              imgSrc={project.mac}         
              href={`/Projects/detail/${index}`} 
              currentlangugae={currentLanguage}
            />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default HoverImageLinks;
