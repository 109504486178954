import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";  // Import GA4
import ScreenOrientationMessage from "../src/screen_rotation/Screen.jsx";
import { PopupProvider } from "./Popup/Popupcontext";
import Scrollering from "../src/ImageSlider/Main_fourth";
import Chat from "./Chat/Chat.jsx";
// Direct imports of components
import HoverImageLinks from "./Third_page/Projects";
import Navbar from "./navbar/Navbar";
import Splashscreen from "../src/Splashscreen/Splashscreen";
import Scrollingtext from "./Scrollingtext";
import Fotters from "./Fotter/Fotters";
import Appmain from "./Appmain_";
import About from "./ABOUT/About";
import Contact from "./Contact/Contact";
import Detail from "./Third_page/Detail";
import Pagenotfound from "./Pagenotfound/Pagenotfound";
import Porjectsim from "./Third_page/Porjects_im";
import Sliderdetails from "./ImageSlider/Sliderdetails";
import Linear from "./Linear_regression/Linear";

function AppContent() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // `useLocation` used here

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-XCJPKN9HEL"); // Replace with your GA4 Measurement ID

    // Track initial page load
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    // Track page view on route change
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    const loadLocomotiveScroll = async () => {
      const LocomotiveScroll = (await import('locomotive-scroll')).default;
      new LocomotiveScroll();

      setTimeout(() => {
        setIsLoading(false);
        document.body.style.cursor = 'default';
        window.scrollTo(0, 0);
      }, 2000);
    };

    loadLocomotiveScroll();
  }, []);

  return (
    <div className="App">
      <ScreenOrientationMessage />
      {isLoading && <Splashscreen />}
      <PopupProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Appmain />} />
          <Route path="/second" element={<Scrollingtext />} />
          <Route path="/Projects" element={<HoverImageLinks />} />
          <Route path="/Projects" element={<Porjectsim />} />
          <Route path="/Projects/detail/:id" element={<Detail />} />
          <Route path="/images_scrool" element={<Scrollering />} />
          <Route path="/images_scrool/detail/:sliderNumber/:index" element={<Sliderdetails />} />
          <Route path="/About" element={<About />} />
          <Route path="/Projects/detail/linearregression" element={<Linear />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Chat" element={<Chat></Chat>}/>
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
        {/* Conditionally render the footer, hide it on the Contact page */}
        {location.pathname !== "/Contact" && <Fotters />}
      </PopupProvider>
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
