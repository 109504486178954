import React, { Suspense, lazy } from 'react';
// Lazy loading the components
const Mainpage = lazy(() => import('./Mainpage/Mainpage'));
const Scrollingtext = lazy(() => import('./Scrollingtext'));
const Scrollering = lazy(() => import('./ImageSlider/Main_fourth'));
const Porjectsim = lazy(() => import('./Third_page/Porjects_im'));
function Appmain() {
  return (
    <div className="overflow-hidden">
      <Suspense fallback={<div>Loading...</div>}>
        <Mainpage />
        <Scrollingtext />
        <Porjectsim />
        <Scrollering />
      </Suspense>
    </div>
  );
}
export default Appmain;
