import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { PopupContext } from '../Popup/Popupcontext';

function Award() {
  const {currentLanguage} = useContext(PopupContext)
  return (
    <div className='flex flex-col items-center mt-16 md:mt-28 mb-32'>
      <motion.div className='flex flex-col md:flex-row items-center justify-center md:gap-16'>
        <div className='flex-shrink-0'>
         
           <motion.picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet="https://s3.us-east-1.amazonaws.com/nandhieswar.site/Award-2.jpg"
                  />
                  <source media="(min-width: 769px)" srcSet="https://s3.us-east-1.amazonaws.com/nandhieswar.site/Award-2.jpg" />
                  <img
                    src="https://s3.us-east-1.amazonaws.com/nandhieswar.site/Award-2.jpg"
                    alt="Award"
                    className='object-cover h-[400px] w-[300px] md:h-[600px] md:w-[400px]'
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.5 }}
                  />
                </motion.picture>
        </div>
        <div className='flex flex-col justify-center items-center md:items-start md:ml-10 text-center md:text-left vsm:justify-center vsm:mt-10 vsm:items-center'>
          <div className='relative vsm:items-center flex justify-center mb-10'>
            <motion.svg
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
              className='w-32 h-32'
            >
              <text
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="11"
              >
                <textPath xlinkHref="#circle-path" className='leading-10 tracking-[8px] uppercase'>
                  {currentLanguage.journey}
                </textPath>
              </text>
              <text x="50" y="50" textAnchor="middle" dominantBaseline="middle" fontSize="16" fill="black" className='font-extrabold uppercase text-[#9F8170]'>
                W
              </text>
              <path
                id="circle-path"
                d="M50 10 A 40 40, 0, 1, 1, 10 50 A 40 40, 0, 1, 1, 50 90 A 40 40, 0, 1, 1, 90 50 A 40 40, 0, 1, 1, 50 10"
                fill="none"
              />
            </motion.svg>
          </div>
          <h1 className='text-4xl md:text-5xl font-bold mb-4'>
            {currentLanguage.award_of}
          </h1>
          <h1 className='text-4xl md:text-5xl font-bold'>
            {currentLanguage.the_data}
          </h1>
          <p className='max-w-[600px] mt-6 text-lg leading-loose'>
            {currentLanguage.award}
          </p>
        </div>
      </motion.div>
    </div>
  );
}

export default Award;
