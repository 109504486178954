import React, { useState, useEffect } from "react";

function ScreenOrientationMessage() {
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024); // Detect if it's a mobile/tablet device

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
      setIsMobile(window.innerWidth <= 1024); // Adjust this value to your target mobile breakpoint
    };

    // Add event listener for orientation change and window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isPortrait && isMobile && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-95 z-50">
          <div className="text-center p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4 text-red-600">Better to View in Portrait Mode</h2>
            <p className="text-gray-600">Please rotate your device to portrait mode for the best experience.</p>
          </div>
        </div>
      )}
    </>
  );
}

export default ScreenOrientationMessage;
