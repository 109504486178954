import React, { useEffect, useRef } from "react";
import gsap from "gsap";

export default function Magnetic({ children }) {
  const magnetic = useRef(null);

  useEffect(() => {
    // Check if magnetic.current exists before proceeding
    if (magnetic.current) {
      const xTo = gsap.quickTo(magnetic.current, "x", {
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      });
      const yTo = gsap.quickTo(magnetic.current, "y", {
        duration: 1,
        ease: "elastic.out(1, 0.3)",
      });

      const handleMouseMove = (e) => {
        if (!magnetic.current) return;  // Early exit if magnetic.current is null
        
        const { clientX, clientY } = e;
        const { height, width, left, top } = magnetic.current.getBoundingClientRect();
        const x = clientX - (left + width / 2);
        const y = clientY - (top + height / 2);
        xTo(x * 0.35);
        yTo(y * 0.35);

        // Ensure magnetic.current exists before querying
        const textElement = magnetic.current.querySelector(".text");
        if (textElement) {
          gsap.to(textElement, {
            x: x * 0.35,
            y: y * 0.35,
            duration: 1,
            ease: "elastic.out(1, 0.3)",
          });
        }
      };

      const handleMouseLeave = () => {
        if (!magnetic.current) return;  // Early exit if magnetic.current is null

        xTo(0);
        yTo(0);

        // Ensure magnetic.current exists before querying
        const textElement = magnetic.current.querySelector(".text");
        if (textElement) {
          gsap.to(textElement, {
            x: 0,
            y: 0,
            duration: 1,
            ease: "elastic.out(1, 0.3)",
          });
        }
      };

      // Attach event listeners
      magnetic.current.addEventListener("mousemove", handleMouseMove);
      magnetic.current.addEventListener("mouseleave", handleMouseLeave);

      // Cleanup event listeners when component unmounts
      return () => {
        if (magnetic.current) {
          magnetic.current.removeEventListener("mousemove", handleMouseMove);
          magnetic.current.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    }
  }, []);

  return React.cloneElement(children, { ref: magnetic });
}
